import { Avatar, Button } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from '../../theme';

export const Paper = styled.div`
  margin-top: ${theme.spacing(8)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginAvatar = styled(Avatar)`
  margin: ${theme.spacing(1)}px;
  background-color: ${theme.palette.secondary.main};
`;

export const Form = styled.form`
  width: 100%;
  margin-top: ${theme.spacing(1)}px;
`;

export const SubmitButton = styled(Button)`
  margin-top: ${theme.spacing(3)}px;
`;