import { AlbumDTO } from "../../dto/AlbumDTO";
import { api } from "../api";

export default async function getAlbumById(
  album_id: string
): Promise<AlbumDTO | null> {
  try {
    const { data } = await api.get<AlbumDTO>(`albums/list/${album_id}`);

    return data;
  } catch (error) {
    return null;
  }
}
