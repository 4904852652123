import { useCallback } from "react";
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

interface Props extends DialogProps {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  cancelLabel?: string;
  confirmLabel?: string;
}

export function ModalAlertConfirm({
  message,
  onConfirm,
  onCancel,
  cancelLabel,
  confirmLabel,
  title,
  ...rest
}: Props) {
  const handleClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
      {...rest}
    >
      {!!title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="secondary">
          {confirmLabel || "Remover"}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {cancelLabel || "Cancelar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
