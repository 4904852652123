import { DragEvent, useCallback, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import {
  Publish as UploadIcon,
  Cancel as DeleteIcon,
} from "@material-ui/icons";

import {
  Container,
  Title,
  InputFile,
  Card,
  CardMedia,
  DropContainer,
  CanvasFiles,
  DeleteButton,
} from "./styles";

interface Props {
  onChange: (data: File[]) => void;
}

export function FileDragDrop({ onChange }: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [isDragActive, setIsDragActive] = useState(false);

  const validateFile = useCallback((file: File) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  }, []);

  const handleFiles = useCallback(
    (files: FileList) => {
      const values = [...selectedFiles];

      for (let i = 0; i < files.length; i++) {
        if (validateFile(files[i])) {
          values.push(files[i]);
        }
      }

      setSelectedFiles(values);
      onChange(values);
    },
    [validateFile, selectedFiles, onChange]
  );

  const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDragActive(true);
  }, []);

  const onDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDragActive(true);
  }, []);

  const onDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDragActive(false);
  }, []);

  const onDragDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      setIsDragActive(false);

      const files = e.dataTransfer.files;
      if (files.length) {
        handleFiles(files);
      }
    },
    [handleFiles]
  );

  const handleRemoveFile = useCallback(
    (idx: number) => {
      const files = [...selectedFiles];
      files.splice(idx, 1);
      setSelectedFiles(files);
    },
    [selectedFiles]
  );

  const handleSelectFiles = useCallback(() => {
    fileInputRef?.current?.click();
  }, []);

  const onInputSelectFiles = useCallback(() => {
    if (fileInputRef?.current?.files?.length) {
      handleFiles(fileInputRef.current.files);
    }
  }, [handleFiles]);

  return (
    <Container>
      <DropContainer
        onClick={handleSelectFiles}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDragDrop}
        isActive={isDragActive}
      >
        <InputFile
          ref={fileInputRef}
          accept="image/*"
          multiple
          type="file"
          onChange={onInputSelectFiles}
        />

        <UploadIcon color="primary" fontSize="large" />
        <Title variant="body1">Enviar Fotos</Title>
      </DropContainer>

      {selectedFiles.length > 0 && (
        <CanvasFiles container spacing={4}>
          {selectedFiles.map((file, idx) => (
            <Grid item key={file.name} xs={6} sm={4} md={3} lg={2}>
              <Card>
                <CardMedia image={URL.createObjectURL(file)} title={file.name}>
                  <DeleteButton onClick={() => handleRemoveFile(idx)}>
                    <DeleteIcon color="secondary" />
                  </DeleteButton>
                </CardMedia>
              </Card>
            </Grid>
          ))}
        </CanvasFiles>
      )}
    </Container>
  );
}
