import { useEffect, useState, useCallback, MouseEvent } from "react";
import {
  Grid,
  Button,
  CardActionArea,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Settings as SettingsIcon,
  Instagram as InstagramIcon,
  Close as DeleteIcon,
  ArrowUpward as UpIcon,
  ArrowDownward as DownIcon,
} from "@material-ui/icons";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import yellow from "@material-ui/core/colors/yellow";

import { PhotoDTO } from "../../dto/PhotoDTO";
import getAlbumById from "../../services/album/getAlbumById";
import getPhotos from "../../services/album/getPhotosFromAlbum";
import { Header } from "../../components/Header";
import { Breadcrumb } from "../../components/Breadcrumb";
import { ModalUploadImage } from "../../components/ModalUploadImage";
import { useAuth } from "../../hooks/useAuth";
import { baseURL } from "../../services/api";
import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";
import getAlbumInstagram from "../../services/instagram/getAlbumInstagram";
import { ModalAlbumInstagram } from "../../components/ModalAlbumInstagram";
import { ModalAlbumInstagramStories } from "../../components/ModalAlbumInstagramStories";
import { ModalAlbumInstagramPosts } from "../../components/ModalAlbumInstagramPosts";
import { ModalAlertConfirm } from "../../components/ModalAlertConfirm";
import { AlbumDTO } from "../../dto/AlbumDTO";
import deletePhotoById from "../../services/photo/deletePhotoById";
import deleteAlbumById from "../../services/album/deleteAlbumById";
import { ImageSlider, ImgView } from "../../components/ImageSlider";

import {
  Container,
  Card,
  CardMedia,
  ActionsBar,
  PhotoButton,
  PhotoActions,
} from "./styles";
import updatePhotoPosition from "../../services/photo/updatePhotoPosition";

import "react-image-gallery/styles/css/image-gallery.css";

interface ParamsTypes {
  id: string;
}

type ModalType =
  | "settings-instagram"
  | "settings-delete"
  | "instagram-stories"
  | "instagram-posts"
  | "upload-photo";

export function Album() {
  const history = useHistory();

  const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(
    null
  );
  const [anchorInstagram, setAnchorInstagram] = useState<null | HTMLElement>(
    null
  );

  const [isModalOpen, setIsModalOpen] = useState<ModalType | null>(null);

  const [photoToRemove, setPhotoToRemove] = useState<string | null>(null);
  const [albumToRemove, setAlbumToRemove] = useState<string | null>(null);

  const [isSlideshowOpen, setIsSlideshowOpen] = useState(false);
  const [slideshowIndex, setSlideshowIndex] = useState(0);

  const { id } = useParams<ParamsTypes>();

  const [album, setAlbum] = useState<null | AlbumDTO>(null);

  const { token } = useAuth();

  const [photoProvider, setPhotoProvider] = useState<PhotoDTO[]>([]);
  const [imagesPaths, setImagesPath] = useState<ImgView[]>([]);

  const [instagramProvider, setInstagramProvider] = useState<
    InstagramAlbumDTO[]
  >([]);

  const loadPhotos = useCallback(async () => {
    if (!id) return;

    const response = await getPhotos(id);
    setPhotoProvider(response);

    setImagesPath(
      response.map((photo) => ({
        original: `${baseURL}/photos/view/${photo.id}/${token}`,
        thumbnail: `${baseURL}/photos/thumb/${photo.id}/${token}`,
      }))
    );
    setSlideshowIndex(0);
  }, [id, token]);

  const loadInstagramAlbum = useCallback(async () => {
    if (!id) return;

    const response = await getAlbumInstagram(id);
    setInstagramProvider(response);
  }, [id]);

  const loadAlbumData = useCallback(async () => {
    if (!id) return;

    const response = await getAlbumById(id);

    if (!response) {
      toast("Não foi possível acessar o álbum", { type: "error" });

      history.push("/");

      return;
    }

    setAlbum(response);
  }, [id, history]);

  useEffect(() => {
    loadAlbumData();
  }, [loadAlbumData]);

  useEffect(() => {
    loadPhotos();
  }, [loadPhotos]);

  useEffect(() => {
    loadInstagramAlbum();
  }, [loadInstagramAlbum]);

  const handleOpenSlideShow = useCallback((idx: number) => {
    setSlideshowIndex(idx);
    setIsSlideshowOpen(true);
  }, []);

  const handleCloseSlideShow = useCallback(() => {
    setIsSlideshowOpen(false);
    setSlideshowIndex(0);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(null);
  }, []);

  const handleSaveUploadModal = useCallback(() => {
    setIsModalOpen(null);
    loadPhotos();
  }, [loadPhotos]);

  const handleOpenSettingsMenu = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorSettings(e.currentTarget);
    },
    []
  );

  const handleCloseSettingsMenu = useCallback(() => {
    setAnchorSettings(null);
  }, []);

  const handleOpenInstagramMenu = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorInstagram(e.currentTarget);
    },
    []
  );

  const handleCloseInstagramMenu = useCallback(() => {
    setAnchorInstagram(null);
  }, []);

  const handleMenu = useCallback(
    (action: string) => {
      switch (action) {
        case "settings-instagram":
          setIsModalOpen("settings-instagram");
          break;

        case "settings-delete":
          setAlbumToRemove(id);
          break;

        case "instagram-stories":
          setIsModalOpen("instagram-stories");
          break;

        case "instagram-posts":
          setIsModalOpen("instagram-posts");
          break;

        default:
          break;
      }

      setAnchorInstagram(null);
      setAnchorSettings(null);
    },
    [id]
  );

  const handleSaveInstagramModal = useCallback(() => {
    loadInstagramAlbum();
    setIsModalOpen(null);
  }, [loadInstagramAlbum]);

  const handleSaveInstagramStoriesModal = useCallback(() => {
    loadPhotos();
    setIsModalOpen(null);
  }, [loadPhotos]);

  const handleSaveInstagramPostsModal = useCallback(() => {
    loadPhotos();
    setIsModalOpen(null);
  }, [loadPhotos]);

  const handleCloseDeleteModal = useCallback(() => {
    setPhotoToRemove(null);
    setAlbumToRemove(null);
  }, []);

  const handleConfirmDeleteModal = useCallback(async () => {
    if (photoToRemove !== null) {
      deletePhotoById(photoToRemove);

      setPhotoProvider((state) =>
        state.filter((item) => item.id !== photoToRemove)
      );

      setPhotoToRemove(null);

      return;
    }

    if (albumToRemove !== null) {
      await deleteAlbumById(albumToRemove);

      history.push("/");

      return;
    }
  }, [photoToRemove, albumToRemove, history]);

  const handleRemoveFile = useCallback(async (photo: PhotoDTO) => {
    setPhotoToRemove(photo.id);
  }, []);

  const handlePositionPhoto = useCallback(
    async (photoId: string, position: number) => {
      await updatePhotoPosition({
        photoId,
        position,
      });

      loadPhotos();
    },
    [loadPhotos]
  );

  return (
    <>
      <Header />

      {album && (
        <>
          <Breadcrumb
            paths={[
              {
                text: "Home",
                to: "/",
              },
              {
                text: album.name,
                to: `/album/${album.id}`,
              },
            ]}
          />

          <Container maxWidth={false}>
            <ActionsBar>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setIsModalOpen("upload-photo")}
              >
                Enviar Imagem
              </Button>

              {instagramProvider.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<InstagramIcon />}
                    onClick={handleOpenInstagramMenu}
                    aria-controls="instagram-menu"
                    aria-haspopup="true"
                  >
                    Instagram
                  </Button>
                  <Menu
                    id="instagram-menu"
                    anchorEl={anchorInstagram}
                    keepMounted
                    open={Boolean(anchorInstagram)}
                    onClose={handleCloseInstagramMenu}
                  >
                    <MenuItem onClick={() => handleMenu("instagram-stories")}>
                      Salvar Stories
                    </MenuItem>
                    <MenuItem onClick={() => handleMenu("instagram-posts")}>
                      Salvar Posts
                    </MenuItem>
                  </Menu>
                </>
              )}

              <IconButton
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleOpenSettingsMenu}
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                id="settings-menu"
                anchorEl={anchorSettings}
                keepMounted
                open={Boolean(anchorSettings)}
                onClose={handleCloseSettingsMenu}
              >
                <MenuItem onClick={() => handleMenu("settings-instagram")}>
                  Gerenciar conta Instagram
                </MenuItem>
                <MenuItem onClick={() => handleMenu("settings-delete")}>
                  Remover álbum
                </MenuItem>
              </Menu>
            </ActionsBar>

            <Grid container spacing={4}>
              {photoProvider.map((photo, idx) => (
                <Grid item key={photo.id} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardActionArea onClick={() => handleOpenSlideShow(idx)}>
                      <CardMedia
                        image={`${baseURL}/photos/thumb/${photo.id}/${token}`}
                        title={photo.filepath}
                      />
                    </CardActionArea>

                    <PhotoActions>
                      <PhotoButton
                        onClick={() =>
                          handlePositionPhoto(photo.id, photo.position + 1)
                        }
                      >
                        <UpIcon style={{ color: yellow[900] }} />
                      </PhotoButton>

                      <PhotoButton
                        onClick={() =>
                          handlePositionPhoto(photo.id, photo.position - 1)
                        }
                      >
                        <DownIcon style={{ color: yellow[900] }} />
                      </PhotoButton>

                      <PhotoButton onClick={() => handleRemoveFile(photo)}>
                        <DeleteIcon color="secondary" />
                      </PhotoButton>
                    </PhotoActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>

          {isSlideshowOpen && (
            <ImageSlider
              images={imagesPaths}
              index={slideshowIndex}
              onClose={handleCloseSlideShow}
            />
          )}

          <ModalUploadImage
            open={isModalOpen === "upload-photo"}
            onClose={handleCloseModal}
            onSave={handleSaveUploadModal}
            album_id={id}
          />

          <ModalAlbumInstagram
            open={isModalOpen === "settings-instagram"}
            onClose={handleCloseModal}
            onSave={handleSaveInstagramModal}
            albumId={id}
          />

          <ModalAlbumInstagramStories
            open={isModalOpen === "instagram-stories"}
            onClose={handleCloseModal}
            onSave={handleSaveInstagramStoriesModal}
            albumId={id}
            instagramProvider={instagramProvider}
          />

          <ModalAlbumInstagramPosts
            open={isModalOpen === "instagram-posts"}
            onClose={handleCloseModal}
            onSave={handleSaveInstagramPostsModal}
            albumId={id}
            instagramProvider={instagramProvider}
          />

          <ModalAlertConfirm
            open={photoToRemove !== null}
            title="Confirmar Exclusão"
            message="Deseja realmente remover a imagem?"
            onCancel={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteModal}
          />

          <ModalAlertConfirm
            open={albumToRemove !== null}
            title="Confirmar Exclusão"
            message="Deseja realmente remover o álbum?"
            onCancel={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteModal}
          />
        </>
      )}
    </>
  );
}
