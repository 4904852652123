import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";
import { api } from "../api";

interface Request {
  album_id: string;
  profile: string;
  instagram_user_id: string;
}

export default async function createAlbumInstagram({
  album_id,
  profile,
  instagram_user_id,
}: Request): Promise<InstagramAlbumDTO | null> {
  try {
    const { data } = await api.post<InstagramAlbumDTO>(
      `instagram/accounts/${album_id}`,
      {
        profile,
        instagram_user_id,
      }
    );

    return data;
  } catch (error) {
    return null;
  }
}
