import { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogProps,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { validationSchema } from "./validationSchema";
import getAlbumInstagramPosts from "../../services/instagram/getAlbumInstagramPosts";
import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";
import { PhotoDTO } from "../../dto/PhotoDTO";
import { GridListImages } from "../GridListImages";

interface Props extends DialogProps {
  onClose: () => void;
  onSave: () => void;
  albumId: string;
  instagramProvider: InstagramAlbumDTO[];
}

interface FormValues {
  album_instagram_account_id: string;
  date: Date;
}

export function ModalAlbumInstagramPosts({
  open,
  albumId,
  onSave,
  onClose,
  instagramProvider,
  ...rest
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [savedPhotos, setSavedPhotos] = useState<PhotoDTO[]>([]);

  const [initialValues, setInitialValues] = useState<FormValues>({
    album_instagram_account_id: "",
    date: new Date(),
  });

  useEffect(() => {
    if (!open) {
      setSavedPhotos([]);
    } else {
      if (instagramProvider.length > 0) {
        const firstValue = instagramProvider[0].id;

        setInitialValues({
          album_instagram_account_id: firstValue,
          date: new Date(),
        });
      }
    }
  }, [open, instagramProvider]);

  const handleCreate = useCallback(async (data: FormValues) => {
    setIsLoading(true);

    const response = await getAlbumInstagramPosts(data);

    if (response.length === 0) {
      toast("Não foi encontrado nenhum post", { type: "error" });
      return;
    } else {
      setSavedPhotos(response);
    }

    setIsLoading(false);
  }, []);

  const handleFinish = useCallback(() => {
    onSave();
  }, [onSave]);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      scroll="paper"
      {...rest}
    >
      <DialogTitle id="form-dialog-title">Salvar Posts</DialogTitle>

      {savedPhotos.length === 0 ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleCreate}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="album_instagram_account_id"
                      label="Conta Instagram"
                      name="album_instagram_account_id"
                      onChange={handleChange}
                      value={values.album_instagram_account_id}
                      error={!!errors.album_instagram_account_id}
                      helperText={errors.album_instagram_account_id || ""}
                    >
                      {instagramProvider.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.profile} / ${item.instagram_account.username}`}
                        </MenuItem>
                      ))}
                    </TextField>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        disableToolbar
                        required
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date"
                        name="date"
                        label="Data do Post"
                        value={values.date}
                        onChange={(value) => setFieldValue("date", value)}
                        fullWidth
                        error={!!errors.date}
                        helperText={errors.date || ""}
                      />
                    </MuiPickersUtilsProvider>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={onClose}
                      color="primary"
                      disabled={isLoading}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" color="primary" disabled={isLoading}>
                      Salvar
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          </Formik>
        </>
      ) : (
        <>
          <DialogContent>
            <GridListImages imageList={savedPhotos} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleFinish} color="primary">
              Concluir
            </Button>
          </DialogActions>
        </>
      )}

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
