import { api } from "../api";

interface Request {
  photoId: string;
  position: number;
}

export default async function updatePhotoPosition({
  photoId,
  position,
}: Request): Promise<boolean> {
  try {
    await api.put(`photos/position/${photoId}`, {
      position,
    });

    return true;
  } catch (error) {
    return false;
  }
}
