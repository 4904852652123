import styled from 'styled-components';
import {
  Container as ContainerUI,
  Card as CardUI,
  CardMedia as CardMediaUI,
  CardContent as CardContentUI,
  Box as BoxUI
} from '@material-ui/core';

import { theme } from '../../theme';

export const Container = styled(ContainerUI)`
  padding-top: ${theme.spacing(4)}px;
`;

export const Card = styled(CardUI)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardMedia = styled(CardMediaUI)`
  padding-top: 75%;
`;

export const CardContent = styled(CardContentUI)`
  flex-grow: 1;
`;

export const ActionsBar = styled(BoxUI)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${theme.spacing(4)}px;
`;
