import { api } from "../api";

export default async function deletePhotoById(
  photoId: string
): Promise<boolean> {
  try {
    await api.delete(`photos/delete/${photoId}`);

    return true;
  } catch (error) {
    return false;
  }
}
