import { useCallback, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  LinearProgress,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Formik } from "formik";

import { validationSchema } from "./validationSchema";
import { PhotoDTO } from "../../dto/PhotoDTO";
import getAlbumInstagramStories from "../../services/instagram/getAlbumInstagramStories";
import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";
import { GridListImages } from "../GridListImages";

interface Props extends DialogProps {
  albumId: string;
  onClose: () => void;
  onSave: () => void;
  instagramProvider: InstagramAlbumDTO[];
}

type ModalType = "form" | "list";

interface FormValues {
  instagram_user_id: string;
}

export function ModalAlbumInstagramStories({
  open,
  albumId,
  onSave,
  onClose,
  instagramProvider,
  ...rest
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const [modalType, setModalType] = useState<ModalType>("form");
  const [savedPhotos, setSavedPhotos] = useState<PhotoDTO[]>([]);

  const [initialValues, setInitialValues] = useState<FormValues>({
    instagram_user_id: "",
  });

  const downloadStories = useCallback(async (albumInstagramId: string) => {
    setIsLoading(true);

    const response = await getAlbumInstagramStories(albumInstagramId);
    setSavedPhotos(response);

    setModalType("list");

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (open) {
      setModalType("form");

      if (instagramProvider.length > 0) {
        const firstValue = instagramProvider[0].id;

        setInitialValues({ instagram_user_id: firstValue });
      }
    }
  }, [open, instagramProvider]);

  const handleFinish = useCallback(() => {
    onSave();
  }, [onSave]);

  const handleDownload = useCallback(
    (data: FormValues) => {
      downloadStories(data.instagram_user_id);
    },
    [downloadStories]
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      scroll="paper"
      {...rest}
    >
      <DialogTitle id="form-dialog-title">Salvar Stories</DialogTitle>

      {modalType === "form" ? (
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleDownload}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="instagram_user_id"
                      label="Conta Instagram"
                      name="instagram_user_id"
                      onChange={handleChange}
                      value={values.instagram_user_id}
                      error={!!errors.instagram_user_id}
                      helperText={errors.instagram_user_id || ""}
                    >
                      {instagramProvider.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.profile} / ${item.instagram_account.username}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={onClose}
                      color="primary"
                      disabled={isLoading}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" color="primary" disabled={isLoading}>
                      Baixar
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          </Formik>
        </>
      ) : (
        <>
          <DialogContent>
            <GridListImages imageList={savedPhotos} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary" disabled={isLoading}>
              Cancelar
            </Button>
            <Button onClick={handleFinish} color="primary" disabled={isLoading}>
              Concluir
            </Button>
          </DialogActions>
        </>
      )}

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
