import { PhotoDTO } from "../../dto/PhotoDTO";
import { api } from "../api";

export default async function getPhotos(album_id: string): Promise<PhotoDTO[]> {
  try {
    const { data } = await api.get<PhotoDTO[]>(
      `albums/list/${album_id}/photos`
    );

    return data;
  } catch (error) {
    return [];
  }
}
