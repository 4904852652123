import { useState, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { AccountCircle, Instagram as InstagramIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useModal } from "../../hooks/useModal";

import { CameraIcon, Title } from "./styles";

export function Header() {
  const history = useHistory();
  const { logout } = useAuth();
  const { openModal } = useModal();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleNavigate = useCallback(
    (page = null) => {
      setAnchorEl(null);

      if (page === "logout") {
        logout();
        history.push("/");
        return;
      }

      if (page) {
        history.push(page);
      }
    },
    [history, logout]
  );

  const handleOpenInstagramModal = useCallback(() => {
    openModal("instagram");
  }, [openModal]);

  return (
    <AppBar position="static">
      <Toolbar>
        <CameraIcon />
        <Title variant="h6" color="inherit" noWrap>
          PICS
        </Title>

        <div>
          <IconButton
            aria-label="instagram"
            onClick={handleOpenInstagramModal}
            color="inherit"
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={() => handleNavigate()}
          >
            <MenuItem onClick={() => handleNavigate("/profile")}>
              Perfil
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/accounts")}>
              Contas
            </MenuItem>
            <Divider light />
            <MenuItem onClick={() => handleNavigate("logout")}>Sair</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
