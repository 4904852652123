import { useRef, useCallback, useEffect } from "react";
import ImageGallery from "react-image-gallery";

import { Container } from "./styles";

export interface ImgView {
  original: string;
  thumbnail: string;
}

interface ImageSliderProps {
  images: ImgView[];
  index: number;
  onClose: () => void;
}

export function ImageSlider({ images, index, onClose }: ImageSliderProps) {
  const galleryRef = useRef<ImageGallery>(null);

  useEffect(() => {
    if (galleryRef && galleryRef.current) {
      galleryRef.current.fullScreen();
    }
  }, [galleryRef]);

  const onCloseHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Container>
      <ImageGallery
        ref={galleryRef}
        items={images}
        infinite
        showPlayButton={false}
        startIndex={index}
        onClick={onCloseHandler}
        lazyLoad
        showFullscreenButton={false}
      />
    </Container>
  );
}
