import { UserDTO } from "../../dto/UserDTO";
import { api } from "../api";

interface Request {
  username: string;
  password: string;
}

interface Response {
  status: boolean;
  message: string,
  token: string;
  user?: UserDTO;
}

export default async function doLogin(params: Request): Promise<Response> {  
  try {
    const { data } = await api.post('sessions', params);

    return {
      status: true,
      message: 'Login efetuado com sucesso!',
      token: data.token,
      user: data.user
    };
  } catch (error) {
    return {
      status: false,
      message: 'Usuário/senha inválidos!',
      token: ''
    };
  }
}