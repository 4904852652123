import axios from "axios";

// const baseURL = "https://apiphoto.almeidaserver.com";
// const baseURL = "http://localhost:3333";
const baseURL = process.env.REACT_APP_SITE_URL;

const api = axios.create({
  baseURL,
});

const setApiToken = (token: string | null) => {
  if (token) {
    api.defaults.headers.authorization = `Bearer ${token}`;
  } else {
    api.defaults.headers.authorization = null;
  }
};

export { api, setApiToken, baseURL };
