import styled, { css } from "styled-components";
import {
  Typography,
  Card as CardUI,
  CardMedia as CardMediaUI,
  Grid,
  IconButton,
} from "@material-ui/core";

import { theme } from "../../theme";

export const Container = styled.div``;

interface DropContainerProps {
  isActive: boolean;
}

export const DropContainer = styled.div<DropContainerProps>`
  width: 100%;
  height: 150px;
  border: 1px dashed ${theme.palette.primary.main};
  border-radius: 6px;
  background-color: ${theme.palette.grey[100]};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  ${(props) =>
    props.isActive &&
    css`
      filter: brightness(0.9);
    `}
`;

export const Title = styled(Typography)`
  color: ${theme.palette.primary.main};
`;

export const InputFile = styled.input`
  display: none;
`;

export const Card = styled(CardUI)`
  height: 100%;
  position: relative;
`;

export const CardMedia = styled(CardMediaUI)`
  padding-top: 75%;
`;

export const CanvasFiles = styled(Grid)`
  margin-top: ${theme.spacing(2)}px;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;
