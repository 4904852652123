import { AlbumDTO } from "../../dto/AlbumDTO";
import { api } from "../api";

interface Request {
  name: string;
  description: string;
}

export default async function createAlbum(params: Request): Promise<AlbumDTO | null> {
  try {
    const { data } = await api.post<AlbumDTO>('albums', params);

    return data;
  } catch (error) {
    return null;
  }
}