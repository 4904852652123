import styled from "styled-components";
import { Container as ContainerUI, Box, Typography } from "@material-ui/core";

import { theme } from "../../theme";

export const Container = styled(ContainerUI)`
  padding-top: ${theme.spacing(4)}px;
`;

export const TitleCanvas = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Typography)``;

export const ListCanvas = styled.div`
  background-color: ${theme.palette.grey[200]};
`;
