import { useEffect, useState, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Tooltip,
} from "@material-ui/core";
import {
  Person as UserIcon,
  AssignmentInd as UpdateUserIcon,
  // Delete as DeleteIcon,
  PersonAdd as UserAddIcon,
  Autorenew as RefreshIcon,
} from "@material-ui/icons";
import { toast } from "react-toastify";

import { InstagramAccountDTO } from "../../dto/InstagramAccountDTO";
import getInstagramAccounts from "../../services/accounts/getInstagramAccounts";
import { Header } from "../../components/Header";
import { Breadcrumb } from "../../components/Breadcrumb";
import refreshAccount from "../../services/instagram/refreshAccount";
import updateFollowees from "../../services/instagram/updateFollowees";

import { Container, Title, ListCanvas, TitleCanvas } from "./styles";
import { ModalNewInstagramAccount } from "../../components/ModalNewInstagramAccount";

export function Accounts() {
  const [isLoading, setIsLoading] = useState(false);

  const [isModalNewInstagramOpen, setIsModalNewInstagramOpen] = useState(false);

  const [instagramProvider, setInstagramProvider] = useState<
    InstagramAccountDTO[]
  >([]);

  const loadInstagramAccounts = useCallback(async () => {
    const response = await getInstagramAccounts();
    setInstagramProvider(response);
  }, []);

  useEffect(() => {
    loadInstagramAccounts();
  }, [loadInstagramAccounts]);

  const handleOpenNewInstagramModal = useCallback(() => {
    setIsModalNewInstagramOpen(true);
  }, []);

  const handleCloseNewInstagramModal = useCallback(() => {
    setIsModalNewInstagramOpen(false);
  }, []);

  const handleOnCreateInstagram = useCallback(() => {
    setIsModalNewInstagramOpen(false);
    loadInstagramAccounts();
  }, [loadInstagramAccounts]);

  const handleRefreshAccount = useCallback(async (userId: string) => {
    setIsLoading(true);

    const response = await refreshAccount(userId);

    if (response) {
      toast("Atualizado com sucesso!", { type: "success" });
    } else {
      toast("Não foi possível atualizar!", { type: "error" });
    }

    setIsLoading(false);
  }, []);

  const handleUpdateFollowees = useCallback(async (userId: string) => {
    setIsLoading(true);

    const response = await updateFollowees(userId);

    if (response.length > 0) {
      toast("Seguidores atualizados com sucesso!", { type: "success" });
    } else {
      toast("Não foi possível atualizar!", { type: "error" });
    }

    setIsLoading(false);
  }, []);

  return (
    <>
      <Header />

      <Breadcrumb
        paths={[
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Contas",
            to: "/accounts",
          },
        ]}
      />

      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TitleCanvas>
              <Title variant="h6">Instagram</Title>

              <IconButton
                aria-label="Add"
                onClick={handleOpenNewInstagramModal}
              >
                <UserAddIcon />
              </IconButton>
            </TitleCanvas>
            <ListCanvas>
              <List dense>
                {instagramProvider.map((item) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <UserIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.username} />
                    <ListItemSecondaryAction>
                      <Tooltip title="Atualizar seguidores">
                        <IconButton
                          edge="end"
                          onClick={() => handleUpdateFollowees(item.id)}
                          disabled={isLoading}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Atualizar conta">
                        <IconButton
                          edge="end"
                          onClick={() => handleRefreshAccount(item.id)}
                          disabled={isLoading}
                        >
                          <UpdateUserIcon />
                        </IconButton>
                      </Tooltip>

                      {/* <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={isLoading}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </ListCanvas>
          </Grid>
        </Grid>
      </Container>

      <ModalNewInstagramAccount
        open={isModalNewInstagramOpen}
        onClose={handleCloseNewInstagramModal}
        onSave={handleOnCreateInstagram}
      />
    </>
  );
}
