import { api } from "../api";

interface Request {
  album_id: string;
  file: File;
  onProgress?: (percentage: number) => void;
  onError?: (message: string) => void;
}

export default async function uploadPhoto({
  album_id,
  file,
  onProgress,
  onError,
}: Request): Promise<void> {
  const formData = new FormData();
  formData.append("image", file);

  await api
    .post(`albums/upload/${album_id}`, formData, {
      onUploadProgress: (progressEvent) => {
        if (onProgress) {
          const uploadPercentage = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );

          onProgress(uploadPercentage);
        }
      },
    })
    .catch(() => {
      if (onError) {
        onError(file.name);
      }
    });
}
