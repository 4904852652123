import { InstagramAccountDTO } from "../../dto/InstagramAccountDTO";
import { api } from "../api";

export default async function refreshAccount(
  instagramUserId: string
): Promise<InstagramAccountDTO | null> {
  try {
    const { data } = await api.get<InstagramAccountDTO>(
      `instagram/refresh/${instagramUserId}`
    );

    return data;
  } catch (error) {
    return null;
  }
}
