import { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Typography,
  Button,
  CardActionArea,
  Link as LinkUI,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { AlbumDTO } from "../../dto/AlbumDTO";
import getAlbums from "../../services/album/getAlbums";
import { Header } from "../../components/Header";
import { ModalNewAlbum } from "../../components/ModalNewAlbum";
import { Breadcrumb } from "../../components/Breadcrumb";
import { baseURL } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";

import { Container, Card, CardMedia, CardContent, ActionsBar } from "./styles";

export function Main() {
  const { token } = useAuth();

  const [isModalNewAlbumOpen, setIsModalNewAlbumOpen] = useState(false);

  const [albumProvider, setAlbumProvider] = useState<AlbumDTO[]>([]);

  const loadAlbums = useCallback(async () => {
    const response = await getAlbums();
    setAlbumProvider(response);
  }, []);

  useEffect(() => {
    loadAlbums();
  }, [loadAlbums]);

  const handleOpenNewAlbumModal = useCallback(() => {
    setIsModalNewAlbumOpen(true);
  }, []);

  const handleCloseNewAlbumModal = useCallback(() => {
    setIsModalNewAlbumOpen(false);
  }, []);

  const handleOnCreateAlbum = useCallback(
    (data: AlbumDTO) => {
      setIsModalNewAlbumOpen(false);
      loadAlbums();
    },
    [loadAlbums]
  );

  const getImageURL = useCallback(
    (album: AlbumDTO) => {
      if (album.photos.length > 0) {
        const photo = album.photos[0];
        return `${baseURL}/photos/thumb/${photo.id}/${token}`;
      }

      return `https://via.placeholder.com/300/ccc.png?text=${album.name}`;
    },
    [token]
  );

  return (
    <>
      <Header />

      <Breadcrumb
        paths={[
          {
            text: "Home",
            to: "/",
          },
        ]}
      />

      <Container maxWidth={false}>
        <ActionsBar>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenNewAlbumModal}
          >
            Novo Álbum
          </Button>
        </ActionsBar>

        <Grid container spacing={4}>
          {albumProvider.map((album) => (
            <Grid item key={album.id} xs={12} sm={6} md={4} lg={3}>
              <LinkUI
                underline="none"
                component={Link}
                to={`/album/${album.id}`}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia image={getImageURL(album)} title={album.name} />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {album.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </LinkUI>
            </Grid>
          ))}
        </Grid>
      </Container>

      <ModalNewAlbum
        open={isModalNewAlbumOpen}
        onClose={handleCloseNewAlbumModal}
        onSave={handleOnCreateAlbum}
      />
    </>
  );
}
