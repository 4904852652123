import styled from "styled-components";

export const Container = styled.div`
  background-color: black;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;

  .image-gallery-content {
    top: 50%;
    transform: translateY(-50%);
  }
`;
