import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import { toast } from "react-toastify";

import { UserDTO } from "../dto/UserDTO";
import { setApiToken } from "../services/api";
import doLogin from "../services/login/doLogin";
import getUser from "../services/user/getUser";

interface AuthProviderProps {
  children: ReactNode;
}

interface LoginInput {
  username: string;
  password: string;
}

interface AuthContextData {
  isAuthenticaded: boolean;
  token: string;
  user: UserDTO | undefined;
  login: (input: LoginInput) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserDTO | undefined>();
  const [token, setToken] = useState("");
  const isAuthenticaded = !!token;

  function logout() {
    setUser(undefined);
    setToken("");
    setApiToken(null);
    localStorage.clear();
  }

  useEffect(() => {
    async function load() {
      const token = localStorage.getItem("@apaphotos-Token");

      if (token) {
        setApiToken(token);
        setToken(token);

        const response = await getUser();
        if (!response) {
          logout();
        }
      }
    }

    load();
  }, []);

  async function login(values: LoginInput) {
    const response = await doLogin(values);

    if (response.status) {
      localStorage.setItem("@apaphotos-Token", response.token);

      setApiToken(response.token);
      setUser(response.user);

      setToken(response.token);
    } else {
      toast(response.message, { type: "error" });
    }
  }

  return (
    <AuthContext.Provider
      value={{ user, login, isAuthenticaded, token, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
