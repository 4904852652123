import { Breadcrumbs, Link as LinkUI } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Container } from "./styles";

interface BreadcrumbLink {
  text: string;
  to: string;
}

interface Props {
  paths: BreadcrumbLink[];
}

export function Breadcrumb({ paths }: Props) {
  return (
    <Container maxWidth={false}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {paths.map((item) => (
          <LinkUI
            key={`bread-${item.to}-${item.text}`}
            color="inherit"
            component={Link}
            to={item.to}
          >
            {item.text}
          </LinkUI>
        ))}
      </Breadcrumbs>
    </Container>
  );
}
