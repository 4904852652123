import { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { Cancel as DeleteIcon } from "@material-ui/icons";

import { PhotoDTO } from "../../dto/PhotoDTO";
import { useAuth } from "../../hooks/useAuth";
import { baseURL } from "../../services/api";
import deletePhotoById from "../../services/photo/deletePhotoById";

import { Card, CardMedia, DeleteButton } from "./styles";

interface Props {
  imageList: PhotoDTO[];
}

export function GridListImages({ imageList }: Props) {
  const { token } = useAuth();

  const [provider, setProvider] = useState<PhotoDTO[]>([]);

  useEffect(() => {
    setProvider(imageList);
  }, [imageList]);

  const handleRemoveFile = useCallback(async (photo: PhotoDTO) => {
    await deletePhotoById(photo.id);
    setProvider((state) => state.filter((item) => item.id !== photo.id));
  }, []);

  return (
    <>
      {provider.length > 0 && (
        <Grid container spacing={4}>
          {provider.map((photo) => (
            <Grid item key={photo.id} xs={6} sm={4} md={3} lg={2}>
              <Card>
                <CardMedia
                  image={`${baseURL}/photos/thumb/${photo.id}/${token}`}
                  title={photo.filepath}
                >
                  <DeleteButton onClick={() => handleRemoveFile(photo)}>
                    <DeleteIcon color="secondary" />
                  </DeleteButton>
                </CardMedia>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
