import { useModal } from "../../hooks/useModal";
import { ModalInstagramDownloader } from "../ModalInstagramDownloader";

export function ModalGlobal() {
  const { isModalOpen, closeModal } = useModal();

  return (
    <>
      <ModalInstagramDownloader
        open={isModalOpen === "instagram"}
        onClose={closeModal}
      />
    </>
  );
}
