import { BrowserRouter, Switch, Route } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { Accounts } from "../pages/Accounts";
import { Album } from "../pages/Album";
import { Login } from "../pages/Login";
import { Main } from "../pages/Main";

export function AppRouter() {
  const { isAuthenticaded } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticaded ? (
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/album/:id" component={Album} />
          <Route path="/accounts" component={Accounts} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/" exact component={Login} />
        </Switch>
      )}
    </BrowserRouter>
  );
}
