import { UserDTO } from "../../dto/UserDTO";
import { api } from "../api";

export default async function getUser(): Promise<UserDTO | null> {
  try {
    const { data } = await api.get<UserDTO>("users");

    return data;
  } catch (error) {
    return null;
  }
}
