import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

import { theme } from "./theme";
import { AuthProvider } from "./hooks/useAuth";
import { ModalProvider } from "./hooks/useModal";
import { ModalGlobal } from "./components/ModalGlobal";
import { AppRouter } from "./routes";

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <ModalProvider>
        <AuthProvider>
          <AppRouter />

          <ModalGlobal />
        </AuthProvider>
      </ModalProvider>

      <ToastContainer />
    </ThemeProvider>
  );
}
