import { useCallback, useState } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Box 
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Formik } from 'formik';

import { 
  Paper, 
  LoginAvatar, 
  Form, 
  SubmitButton 
} from './styles';
import { validationSchema } from './validationSchema';
import { useAuth } from '../../hooks/useAuth';

interface LoginFormValues {
  username: string;
  password: string;
}

export function Login() {
  const { login } = useAuth();
  
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const initialValues: LoginFormValues = {
    username: '',
    password: ''
  }

  const handleLogin = useCallback(async (data) => {
    setIsLoginLoading(true);

    await login(data);

    setIsLoginLoading(false);
  }, [login])

  return (
    <Container component="main" maxWidth="xs">        
      <Paper>
        <LoginAvatar>
          <LockOutlinedIcon />
        </LoginAvatar>
        <Typography component="h1" variant="h5">
          Entrar
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleLogin}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>              
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuário"
                name="username"
                autoFocus
                onChange={handleChange}
                value={values.username}
                error={!!errors.username}
                helperText={errors.username || ''}
              />
              
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                onChange={handleChange}
                value={values.password}
                error={!!errors.password}
                helperText={errors.password || ''}
              />
              
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoginLoading}
              >
                Enviar
              </SubmitButton>
              
            </Form>
          )}
      </Formik>
        
      </Paper>
      <Box mt={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          {`Copyright © APA Photos ${new Date().getFullYear()}`}
      </Typography>
      </Box>
    </Container>
  );
}