import { useCallback, useState } from "react";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogProps,
  LinearProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";

import { validationSchema } from "./validationSchema";
import createAlbum from "../../services/album/createAlbum";
import { AlbumDTO } from "../../dto/AlbumDTO";

interface Props extends DialogProps {
  onClose: () => void;
  onSave: (data: AlbumDTO) => void;
}

interface AlbumFormValues {
  name: string;
  description: string;
}

export function ModalNewAlbum({ onSave, onClose, ...rest }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: AlbumFormValues = {
    name: "",
    description: "",
  };

  const handleCreate = useCallback(
    async (data) => {
      setIsLoading(true);

      const response = await createAlbum(data);

      if (response) {
        onSave(response);
      } else {
        toast("Não foi possível criar o álbum", { type: "error" });
      }

      setIsLoading(false);
    },
    [onSave]
  );

  return (
    <Dialog aria-labelledby="form-dialog-title" {...rest}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleCreate}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <>
            <DialogTitle id="form-dialog-title">Novo Álbum</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  name="name"
                  autoFocus
                  onChange={handleChange}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name || ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="description"
                  label="Descrição"
                  type="description"
                  id="description"
                  multiline
                  rows={4}
                  onChange={handleChange}
                  value={values.description}
                  error={!!errors.description}
                  helperText={errors.description || ""}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary" disabled={isLoading}>
                  Cancelar
                </Button>
                <Button type="submit" color="primary" disabled={isLoading}>
                  Cadastrar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Formik>

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
