import styled from "styled-components";
import { Typography } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import { theme } from "../../theme";

export const CameraIcon = styled(PhotoCamera)`
  margin-right: ${theme.spacing(2)}px;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
`;
