import { PhotoDTO } from "../../dto/PhotoDTO";
import { api } from "../api";

export default async function getAlbumInstagramStories(
  albumInstagramAccountId: string
): Promise<PhotoDTO[]> {
  try {
    const { data } = await api.post<PhotoDTO[]>("instagram/stories", {
      album_instagram_account_id: albumInstagramAccountId,
    });

    return data;
  } catch (error) {
    return [];
  }
}
