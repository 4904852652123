import { useCallback, useState, useEffect, ChangeEvent } from "react";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogProps,
  MenuItem,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
  LinearProgress,
} from "@material-ui/core";
import { Person as PersonIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { toast } from "react-toastify";

import { validationSchema } from "./validationSchema";
import getInstagramAccounts from "../../services/accounts/getInstagramAccounts";
import createAlbumInstagram from "../../services/instagram/createAlbumInstagram";
import getAlbumInstagram from "../../services/instagram/getAlbumInstagram";
import deleteAlbumInstagram from "../../services/instagram/deleteAlbumInstagram";
import { InstagramAccountDTO } from "../../dto/InstagramAccountDTO";
import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";

import { Avatar } from "./styles";

interface Props extends DialogProps {
  onClose: () => void;
  onSave: (data: InstagramAlbumDTO) => void;
  albumId: string;
}

interface FormValues {
  profile: string;
  album_id: string;
  instagram_user_id: string;
}

type ModalType = "form" | "list";

export function ModalAlbumInstagram({
  albumId,
  onSave,
  onClose,
  open,
  ...rest
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const [modalType, setModalType] = useState<ModalType>("list");

  const [providerAccounts, setProviderAccounts] = useState<
    InstagramAccountDTO[]
  >([]);
  const [providerProfiles, setProviderProfiles] = useState<string[]>([]);

  const [providerAlbum, setProviderAlbum] = useState<InstagramAlbumDTO[]>([]);

  const [initialValues, setInitialValues] = useState<FormValues>({
    album_id: "",
    profile: "",
    instagram_user_id: "",
  });

  useEffect(() => {
    setInitialValues({
      album_id: albumId,
      profile: "",
      instagram_user_id: "",
    });
  }, [albumId]);

  const loadInstagramAccounts = useCallback(async () => {
    const response = await getInstagramAccounts();

    setProviderAccounts(response);
  }, []);

  const loadInstagramAlbum = useCallback(async (albumId: string) => {
    const response = await getAlbumInstagram(albumId);
    setProviderAlbum(response);
  }, []);

  const deleteInstagramAlbum = useCallback(
    async (albumInstagramId: string) => {
      await deleteAlbumInstagram(albumInstagramId);
      loadInstagramAlbum(albumId);
    },
    [albumId, loadInstagramAlbum]
  );

  useEffect(() => {
    if (open && providerAccounts.length === 0) {
      setProviderProfiles([]);
      loadInstagramAccounts();
      loadInstagramAlbum(albumId);
    }
  }, [
    providerAccounts,
    open,
    loadInstagramAccounts,
    albumId,
    loadInstagramAlbum,
  ]);

  const handleCreate = useCallback(
    async (data: FormValues) => {
      setIsLoading(true);

      const response = await createAlbumInstagram(data);

      if (response) {
        onSave(response);
      } else {
        toast("Não foi possível cadastrar a conta", { type: "error" });
      }

      loadInstagramAlbum(data.album_id);

      setIsLoading(false);

      setModalType("list");
    },
    [onSave, loadInstagramAlbum]
  );

  const onSelectInstagramUser = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      const userId = event.target.value;

      setFieldValue("instagram_user_id", userId);

      const profile = providerAccounts.find((item) => item.id === userId);

      if (profile) {
        setProviderProfiles(profile.followees);
      }
    },
    [providerAccounts]
  );

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      open={open}
      {...rest}
    >
      <DialogTitle id="form-dialog-title">
        Gerenciar Conta Instagram
      </DialogTitle>

      {modalType === "list" ? (
        <>
          <DialogContent>
            <List dense>
              {providerAlbum.map((item) => (
                <ListItem button key={item.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${item.profile} / ${item.instagram_account.username}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteInstagramAlbum(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => setModalType("form")} color="primary">
              Adicionar
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleCreate}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="instagram_user_id"
                      label="Conta Instagram"
                      name="instagram_user_id"
                      onChange={(event) =>
                        onSelectInstagramUser(event, setFieldValue)
                      }
                      value={values.instagram_user_id}
                      error={!!errors.instagram_user_id}
                      helperText={errors.instagram_user_id || ""}
                    >
                      {providerAccounts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.username}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="profile"
                      label="Perfil"
                      name="profile"
                      onChange={handleChange}
                      value={values.profile}
                      error={!!errors.profile}
                      helperText={errors.profile || ""}
                    >
                      {providerProfiles.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setModalType("list")}
                      color="primary"
                      disabled={isLoading}
                    >
                      Voltar
                    </Button>
                    <Button type="submit" color="primary" disabled={isLoading}>
                      Cadastrar
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          </Formik>
        </>
      )}

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
