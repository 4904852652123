import { api } from "../api";

export default async function deleteAlbumInstagram(
  albumInstagramId: string
): Promise<boolean> {
  try {
    await api.delete(`instagram/accounts/${albumInstagramId}`);

    return true;
  } catch (error) {
    return false;
  }
}
