import styled from "styled-components";
import {
  Container as ContainerUI,
  Card as CardUI,
  CardMedia as CardMediaUI,
  CardContent as CardContentUI,
  Box as BoxUI,
  IconButton,
} from "@material-ui/core";

import { theme } from "../../theme";

export const Container = styled(ContainerUI)`
  padding-top: ${theme.spacing(4)}px;
`;

export const Card = styled(CardUI)`
  height: 100%;
  position: relative;
`;

export const CardMedia = styled(CardMediaUI)`
  padding-top: 75%;
`;

export const CardContent = styled(CardContentUI)`
  flex-grow: 1;
`;

export const ActionsBar = styled(BoxUI)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${theme.spacing(4)}px;

  button {
    & + button {
      margin-left: ${theme.spacing(1)}px;
    }
  }
`;

export const PhotoActions = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.3;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PhotoButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  border-radius: 15px;

  background-color: white;

  margin-bottom: 10px;
`;
