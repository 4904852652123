import { InstagramAccountDTO } from "../../dto/InstagramAccountDTO";
import { api } from "../api";

export default async function getInstagramAccounts(): Promise<
  InstagramAccountDTO[]
> {
  try {
    const { data } = await api.get<InstagramAccountDTO[]>("instagram");

    return data;
  } catch (error) {
    return [];
  }
}
