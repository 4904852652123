import { api } from "../api";

export default async function updateFollowees(
  instagramUserId: string
): Promise<string[]> {
  try {
    const { data } = await api.get<string[]>(
      `instagram/followees/${instagramUserId}`
    );

    return data;
  } catch (error) {
    return [];
  }
}
