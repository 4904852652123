import { api } from "../api";

export default async function deleteAlbumById(
  albumId: string
): Promise<boolean> {
  try {
    await api.delete(`albums/delete/${albumId}`);

    return true;
  } catch (error) {
    return false;
  }
}
