import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";
import { api } from "../api";

export default async function getAlbumInstagram(
  album_id: string
): Promise<InstagramAlbumDTO[]> {
  try {
    const { data } = await api.get<InstagramAlbumDTO[]>(
      `instagram/accounts/${album_id}`
    );

    return data;
  } catch (error) {
    return [];
  }
}
