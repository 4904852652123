import { InstagramAccountDTO } from "../../dto/InstagramAccountDTO";
import { api } from "../api";

interface Request {
  username: string;
  password: string;
}

export default async function createInstagramAccount(
  params: Request
): Promise<InstagramAccountDTO | null> {
  try {
    const { data } = await api.post<InstagramAccountDTO>("instagram", params);

    return data;
  } catch (error) {
    return null;
  }
}
