import styled from "styled-components";
import {
  Card as CardUI,
  CardMedia as CardMediaUI,
  IconButton,
} from "@material-ui/core";

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const Card = styled(CardUI)`
  height: 100%;
  position: relative;
`;

export const CardMedia = styled(CardMediaUI)`
  padding-top: 75%;
`;
