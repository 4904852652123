import { AlbumDTO } from "../../dto/AlbumDTO";
import { api } from "../api";

export default async function getAlbumsWithInstagram(): Promise<AlbumDTO[]> {
  try {
    const { data } = await api.get<AlbumDTO[]>("albums/instagram");

    return data;
  } catch (error) {
    return [];
  }
}
