import { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogProps,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { toast } from "react-toastify";

import { validationSchema } from "./validationSchema";
import getAlbumsWithInstagram from "../../services/album/getAlbumsWithInstagram";
import { AlbumDTO } from "../../dto/AlbumDTO";
import { PhotoDTO } from "../../dto/PhotoDTO";
import { GridListImages } from "../GridListImages";
import getAlbumInstagramStories from "../../services/instagram/getAlbumInstagramStories";
import getAlbumInstagramPosts from "../../services/instagram/getAlbumInstagramPosts";
import { InstagramAlbumDTO } from "../../dto/InstagramAlbumDTO";

interface Props extends DialogProps {
  onClose: () => void;
}

type ModalType = "form" | "result";
type DownloadType = "stories" | "posts";

interface FormValues {
  album_id: string;
  album_instagram_account_id: string;
  type: DownloadType;
  date: Date;
}

export function ModalInstagramDownloader({ onClose, open, ...rest }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const [providerAccounts, setProviderAccounts] = useState<AlbumDTO[]>([]);
  const [providerInstagram, setProviderInstagram] = useState<
    InstagramAlbumDTO[]
  >([]);
  const [savedPhotos, setSavedPhotos] = useState<PhotoDTO[]>([]);

  const [modalType, setModalType] = useState<ModalType>("form");

  const [initialValues, setInitialValues] = useState<FormValues>({
    album_id: "",
    album_instagram_account_id: "",
    type: "stories",
    date: new Date(),
  });

  useEffect(() => {
    if (providerAccounts.length > 0) {
      setInitialValues({
        album_id: providerAccounts[0].id,
        album_instagram_account_id: providerAccounts[0].instagrams[0].id,
        type: "stories",
        date: new Date(),
      });

      setProviderInstagram(providerAccounts[0].instagrams);
    }
  }, [providerAccounts]);

  const loadAlbumInstagram = useCallback(async () => {
    const response = await getAlbumsWithInstagram();
    setProviderAccounts(response);
  }, []);

  const handleAlbumChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: string) => void
    ) => {
      const value = event.target.value;

      const account = providerAccounts.find((item) => item.id === value);

      if (account) {
        setProviderInstagram(account.instagrams);

        if (account.instagrams.length > 0) {
          setFieldValue("album_instagram_account_id", account.instagrams[0].id);
        }
      }

      setFieldValue("album_id", value);
    },
    [providerAccounts]
  );

  useEffect(() => {
    if (open) {
      setModalType("form");
      loadAlbumInstagram();
    } else {
      setSavedPhotos([]);
    }
  }, [open, loadAlbumInstagram]);

  const handleCreate = useCallback(async (data: FormValues) => {
    setIsLoading(true);

    let response: PhotoDTO[] = [];

    if (data.type === "stories") {
      response = await getAlbumInstagramStories(
        data.album_instagram_account_id
      );
    } else if (data.type === "posts") {
      response = await getAlbumInstagramPosts({
        album_instagram_account_id: data.album_instagram_account_id,
        date: data.date,
      });
    }

    setIsLoading(false);

    if (response.length === 0) {
      toast("Não foi encontrado nenhum post", { type: "error" });
      return;
    }

    setSavedPhotos(response);
    setModalType("result");
  }, []);

  const handleFinish = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      scroll="paper"
      open={open}
      {...rest}
    >
      {modalType === "form" ? (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleCreate}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <>
              <DialogTitle id="form-dialog-title">
                Instagram Downloader
              </DialogTitle>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    select
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="album_id"
                    label="Álbum"
                    name="album_id"
                    onChange={(evt) =>
                      handleAlbumChange(
                        evt as React.ChangeEvent<HTMLInputElement>,
                        setFieldValue
                      )
                    }
                    value={values.album_id}
                    error={!!errors.album_id}
                    helperText={errors.album_id || ""}
                  >
                    {providerAccounts.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="album_instagram_account_id"
                    label="Instagram"
                    name="album_instagram_account_id"
                    onChange={handleChange}
                    value={values.album_instagram_account_id}
                    error={!!errors.album_instagram_account_id}
                    helperText={errors.album_instagram_account_id || ""}
                  >
                    {providerInstagram.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {`${item.profile} / ${item.instagram_account.username}`}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="type"
                    label="Tipo"
                    name="type"
                    onChange={handleChange}
                    value={values.type}
                    error={!!errors.type}
                    helperText={errors.type || ""}
                  >
                    <MenuItem value="stories">Stories</MenuItem>
                    <MenuItem value="posts">Posts</MenuItem>
                  </TextField>

                  {values.type === "posts" && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        disableToolbar
                        required
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date"
                        name="date"
                        label="Data do Post"
                        value={values.date}
                        onChange={(value) => setFieldValue("date", value)}
                        fullWidth
                        error={!!errors.date}
                        helperText={errors.date || ""}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={onClose}
                    color="primary"
                    disabled={isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isLoading}>
                    Salvar
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Formik>
      ) : (
        <>
          <DialogTitle id="form-dialog-title">Stories Salvos</DialogTitle>
          <DialogContent>
            <GridListImages imageList={savedPhotos} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary" disabled={isLoading}>
              Cancelar
            </Button>
            <Button onClick={handleFinish} color="primary" disabled={isLoading}>
              Concluir
            </Button>
          </DialogActions>
        </>
      )}

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
