import { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  LinearProgress,
} from "@material-ui/core";

import { validationSchema } from "./validationSchema";
import { FileDragDrop } from "../FileDragDrop";
import uploadPhoto from "../../services/album/uploadPhoto";

interface Props extends DialogProps {
  album_id?: string;
  onClose: () => void;
  onSave: () => void;
}

interface FormValues {
  album_id: string;
  images: File[];
}

export function ModalUploadImage({
  album_id,
  onSave,
  onClose,
  ...rest
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const [initialValues, setInitialValues] = useState<FormValues>({
    album_id: "",
    images: [],
  });

  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    if (album_id) {
      setInitialValues({
        album_id,
        images: [],
      });
    }
  }, [album_id]);

  const handleCreate = useCallback(
    async (data: FormValues) => {
      setIsLoading(true);

      for (let i = 0; i < data.images.length; i++) {
        await uploadPhoto({
          album_id: data.album_id,
          file: data.images[i],
          onProgress: (val) => setCurrentProgress(val),
        });
      }

      onSave();

      setIsLoading(false);
    },
    [onSave]
  );

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      scroll="paper"
      {...rest}
    >
      {isLoading ? (
        <>
          <DialogTitle id="form-dialog-title">Enviando</DialogTitle>
          <DialogContent>
            <LinearProgress variant="determinate" value={currentProgress} />
          </DialogContent>
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleCreate}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, errors, setFieldValue, handleSubmit }) => (
            <>
              <DialogTitle id="form-dialog-title">Enviar Imagens</DialogTitle>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <FileDragDrop
                    onChange={(files) => setFieldValue("images", files)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={onClose}
                    color="primary"
                    disabled={isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isLoading || values.images.length === 0}
                  >
                    Enviar
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
