import { format } from "date-fns";

import { PhotoDTO } from "../../dto/PhotoDTO";
import { api } from "../api";

interface Request {
  album_instagram_account_id: string;
  date: Date;
}

export default async function getAlbumInstagramPosts({
  album_instagram_account_id,
  date,
}: Request): Promise<PhotoDTO[]> {
  try {
    const { data } = await api.post<PhotoDTO[]>("instagram/posts", {
      album_instagram_account_id,
      date: format(date, "yyyy-MM-dd"),
    });

    return data;
  } catch (error) {
    return [];
  }
}
